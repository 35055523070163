import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

// import Medal from '../assets/img/medal.png'
import { Button, Link } from '@material-ui/core'

import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import '../styles/main.css'
// import FacebookIcon from '@material-ui/icons/Facebook';
// import SmallCard from './SmallCard'

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const Sidebar = ({ data }) => {
  const classes = useStyles()
  return (
    <div className='container-fluid mb-4'>
      <div>
        <div className='row text-center'>
          <div className='col-sm-12 p-lg-1'>
            <Link
              target='_blank'
              onClick={() => {
                window.open('https://web.facebook.com/neap.neap.94/', '_blank')
              }}
              title='Follow us on Facebook'
            >
              <Button
                variant='contained'
                color='secondary'
                className='witter-btn'
                style={{ backgroundColor: '#3B5998' }}
              >
                <small>Facebook</small>
              </Button>
            </Link>

            <Link
              target='_blank'
              onClick={() => {
                window.open('https://twitter.com/theneapng', '_blank')
              }}
              title='Follow us on Twitter'
            >
              <Button
                variant='contained'
                className='witter-btn mx-2 my-2'
                style={{ backgroundColor: '#1C9CEA', color: '#FFFFFF' }}
              >
                <small>Twitter</small>
              </Button>
            </Link>

            <Link
              target='_blank'
              onClick={() => {
                window.open(
                  'https://www.linkedin.com/in/neap-nigeria-043309204/',
                  '_blank'
                )
              }}
              title='Connect us on LinkedIn'
            >
              <Button
                variant='contained'
                color='secondary'
                className='witter-btn'
                style={{ backgroundColor: '#0e76a8' }}
              >
                <small>Linkedin</small>
              </Button>
            </Link>
          </div>
          <div className='col-sm-12'>
            <Link
              target='_blank'
              onClick={() => {
                window.open('https://www.instagram.com/theneapng/', '_blank')
              }}
              title='Like us on Instagram'
            >
              <Button
                variant='contained'
                color='primary'
                className='mx-2 my-2 instagram-btn'
              >
                <small>instagram</small>
              </Button>
            </Link>
          </div>
        </div>
        {data.map((item, index) => (
          <div key={index}>
            <div
              className='text-center text-light'
              style={{ backgroundColor: '#24A768' }}
            >
              {item.sidebarTitle}
            </div>
            ​
            <div className='mb-3'>
              <Avatar
                alt='Minister'
                src={item.sidebarImage[0].url}
                variant='square'
                className={classes.large}
                style={{ float: 'left', margin: '5px' }}
              />
              <p>{item.sidebarContent}</p>
              <span>
                <a href={item.sidebarLink}>Read More...</a>{' '}
              </span>
            </div>
          </div>
        ))}
        <div
          className='text-center text-light'
          style={{ backgroundColor: '#24A768' }}
        >
          &nbsp;
        </div>
        <div>{/* Image frame comes here */}</div>
      </div>

      {/* Start of Minister Image frame */}
    </div>
  )
}

export default Sidebar
