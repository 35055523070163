import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { Fragment } from 'react'
import '../styles/main.css'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
// import PaginationRounded from '../components/PaginationRounded'
import Footer from '../components/Footer'

import LargeCard from '../components/LargeCard'
import Header from '../components/Header'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { SCHOOLs_QUERY } from '../queries'
import { useQuery } from '@apollo/client'
import Loading from '../components/loading'
import { states } from "../utils";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 180,
  },
}))

const ExamsAndCompetition = () => {
  const classes = useStyles()
  // const [setAge] = React.useState(10)
  const [state, setState] = React.useState(10);
  // const handleChange = (event) => {
  //   setAge(event.target.value)
  // }
  const handleChange = (event) => {
    setState(event.target.value);
  };
  const { data, error, loading } = useQuery(SCHOOLs_QUERY, { variables: { state: state === 10 ? null : state}})
  error && console.log(error)
  // function ListItemLink(props) {
  //   return <ListItem button component="a" {...props} />;
  // }
  const Title = 'Schools Exams & Competitions | NEAP'

  return (
    <Fragment>
      <div className='container-fluid'>
        <Helmet>
          <title>{Title}</title>
        </Helmet>
        {/* <nav className="navbar navbar-expand-md navbar-light">
          <Link className="navbar-brand">
            <img src="../img/logo2.png" height="46" alt="NEAP" />
          </Link>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav navbar-center">
              <Link className="nav-item nav-link active">HOME</Link>
              <Link className="nav-item nav-link">STUDENTS</Link>
              <Link className="nav-item nav-link">STATE</Link>
              <Link className="nav-item nav-link" tabindex="-1">
                PROFILES
              </Link>
              <Link className="nav-item nav-link" tabindex="-1">
                ABOUT
              </Link>
              <Link className="nav-item nav-link" tabindex="-1">
                BLOG
              </Link>
            </div>
          </div>
          <div className="navbar-nav ml-auto" style={{ padding: "24px" }}>
            <Link className="nav-item" style={{ color: "#000" }}>
              CONTACT US
            </Link>
          </div>
        </nav> */}
        <Header />
        <div id='banner2'>
          <div id='inner-banner' className='text-center'>
            <div id='banner2-text'>
              <p style={{ zIndex: '1', color: '#fff' }}>
                Explore achievements across various exams and competitions for
                students, schools and states
              </p>
              <div>
                <ul className='nav nav-tabs mytabs'>
                  <li className='nav-item'>
                    <Link className='nav-link active' aria-current='page'>
                      WAEC
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link mytabes-disabled' href='#'>
                      NECO
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link mytabes-disabled' href='#'>
                      JAMB
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link mytabes-disabled' href='#'>
                      ISPAK
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link mytabes-disabled' href='#'>
                      Olympiad
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link mytabes-disabled' href='#'>
                      Cowbellpedia
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link mytabes-disabled' href='#'>
                      and more..
                    </Link>
                  </li>
                </ul>
              </div>
              <Link
                target='_blank'
                onClick={() => {
                  window.open('https://www.studylab360.com/', '_blank')
                }}
                style={{ textDecoration: 'none', color: 'black' }}
                title='Visit StudyLab360'
              >
                <p style={{ fontSize: '20px', color: '#fff' }}>
                  powered by studylab360
                </p>
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: '#F8F8F8' }}
          className='text-center mb-4'
        >
          <FormControl className={classes.margin}>
            <InputLabel id='demo-customized-select-label'></InputLabel>
            <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={state}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            <MenuItem value={10}>Nigeria</MenuItem>
                {states.map((state, index) => (
                  <MenuItem value={state} key={index}>{state}</MenuItem>
                ))}
          </Select>
          </FormControl>
          <button
            type='button'
            className='btn'
            style={{
              marginTop: '33px',
              backgroundColor: '#F7700F',
              color: '#FFFFFF',
              padding: '10px',
            }}
          >
            Search
          </button>
        </div>

        {/* Cards */}
        <div className='container'>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className='d-flex flex-row-reverse'>
                <button
                  type='button'
                  className='btn mb-3'
                  data-toggle='modal'
                  data-target='#addSchoolModal'
                  style={{
                    marginTop: '33px',
                    backgroundColor: '#757575',
                    color: '#FFFFFF',
                  }}
                >
                  Submit your school
                </button>
                {/* <!-- Modal --> */}
                <div
                  className='modal fade'
                  id='addSchoolModal'
                  tabIndex='-1'
                  role='dialog'
                  aria-labelledby='addSchoolModalLabel'
                  aria-hidden='true'
                >
                  <div
                    className='modal-dialog modal-dialog-centered'
                    role='document'
                  >
                    <div className='modal-content'>
                      <iframe
                        width='100%'
                        height='660px'
                        title='request form'
                        style={{ border: '0px' }}
                        src='https://bigin.zoho.com/crm/WebFormServeServlet?rid=f6828186cf9c53c3a4aebac12a3c5cc434c460d2dc58b28cd468a353f2098220gidfda9578cca86b5c13716de6430a3038a92cbc5eb10ed77b22d91f6c7ad49baf6'
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              {data.entries.map((school) => (
                <Link to={`/school-profile/${school.slug}`} key={school.id}>
                  <LargeCard school={school} />
                </Link>
              ))}
            </>
          )}
          {/* <PaginationRounded /> */}
        </div>
        {/* Footer part */}
      </div>
      <Footer />
    </Fragment>
  )
}
export default ExamsAndCompetition
