import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import Medal1 from '../assets/img/medal1.png'
import Medal2 from '../assets/img/medal2.png'
import Medal3 from '../assets/img/medal3.png'

import '../styles/main.css'
import SmallCard from './SmallCard'
import { AppDataContext } from '../context/AppDataProvider'
import { useQuery } from '@apollo/client'
import { TOP_THREE_STUDENT_QUERY } from '../queries'
import Loading from './loading'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'yellow !important',
  },
}))

const states = [
  'Abia',
  'Adamawa',
  'Akwa Ibom',
  'Anambra',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Edo',
  'Ekiti',
  'Enugu',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara',
]
const medals = [Medal2, Medal3, Medal1]
const Topstudent = () => {
  const classes = useStyles()
  // const [student, setStudent] = React.useState([{}, {}, {}]);
  const { setState, state } = React.useContext(AppDataContext)

  const { data, error } = useQuery(TOP_THREE_STUDENT_QUERY, {
    variables: { state: state === 10 ? null : state },
  })
  if (error) {
    error && console.log(error)
  }
  const student = data && data.entry.topStudents
  const handleChange = (event) => {
    setState(event.target.value)
  }
  // const bull = <span className={classes.bullet}>•</span>;
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div
          className='col-xl-12'
          style={{ marginRight: '30px', backgroundColor: '#24A768' }}
        >
          <div className='top text-light'>
            <strong style={{ fontSize: '30px', marginRight: '30px' }}>
              Top 3 Students In {state === 10 ? 'Nigeria' : state}
            </strong>
            <FormControl variant='outlined'>
              <InputLabel id='demo-simple-select-outlined-label'>
                Top 3
              </InputLabel>
              <Select
                labelId='demo-simple-select-outlined-label'
                id='demo-simple-select-outlined'
                value={state}
                onChange={handleChange}
                label='Top3'
                style={{
                  width: '300px',
                  color: '#FFFFFF',
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              >
                <MenuItem value={10}>Nigeria</MenuItem>
                {states.map((state, index) => (
                  <MenuItem value={state} key={index}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='my-xl-5 mx-lg-5'>
            <div className='row'>
              {student ? (
                student.map((stu, index) => (
                  <div className='col-sm-4 col-xl-4 mb-3' key={index}>
                    {/* Card comes here */}
                    <SmallCard
                      name={stu?.firstName + ' ' + stu.lastName}
                      image={stu.profileImage && stu.profileImage[0].url}
                      medaltype={medals[index]}
                      score={stu?.points}
                      gender={stu?.gender}
                      school={stu.school[0] && stu.school[0].schoolName}
                      state={stu?.state}
                    />
                  </div>
                ))
              ) : (
                <div style={{ paddingLeft: '300px' }}>
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Sidebar was here */}
      </div>
    </div>
  )
}

export default Topstudent
