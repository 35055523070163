import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

// import Logo from "../assets/img/school.png";
import Waec from "../assets/img/Ellipse 2.png";
import lab from "../assets/img/Ellipse 4.png";
import neco from "../assets/img/Ellipse 3.png";
// import StarIcon from "@material-ui/icons/Star";
// import StarBorderIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useFetchStudents } from "./ListedStudents";
// import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
}));

const waecExams = "exams_waec_BlockType";
const necoExams = "exams_neco_BlockType";
const jambExams = "exams_jamb_BlockType";

function getSchoolScores(
  schoolStudents,
  waecThreshold,
  necoThreshold,
  jambThreshold
) {
  let [numWaecScore, numNecoScore, numJambScore] = [0, 0, 0];
  schoolStudents.forEach((student) => {
    student.exams.forEach((exam) => {
      let studentTotalScore = 0;
      const { __typename, grades } = exam;
      const examType = __typename;
      if (examType === waecExams || examType === necoExams) {
        for (let i = 0; i < grades.length; i++) {
          if (grades[i].score.toLowerCase() === "a1") studentTotalScore += 1;
        }
      } else if (examType === jambExams) {
        studentTotalScore = exam.totalScore;
      }
      if (examType === waecExams && studentTotalScore >= waecThreshold) {
        numWaecScore += 1;
      } else if (examType === necoExams && studentTotalScore >= necoThreshold) {
        numNecoScore += 1;
      } else if (examType === jambExams && studentTotalScore >= jambThreshold) {
        numJambScore += 1;
      }
    });
  });
  return { numWaecScore, numNecoScore, numJambScore };
}

const LargeCard = ({ school }) => {
  const classes = useStyles();
  const { error, loading, data } = useFetchStudents(school.id);
  if (error) {
    console.error(error);
  }

  const { numWaecScore, numNecoScore, numJambScore } = getSchoolScores(
    data?.entries || [],
    5,
    5,
    250
  );
  return (
    <Card className="card my-lg-3 mb-3">
      <CardContent className="justify-content-center">
        <div className="row">
          <div className="col-xl-9">
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                {/* <Avatar
                  alt="Remy Sharp"
                  variant="square"
                  src={props.school.logo[0].url}
                  // style={{ width: "80px", height: "80px" }}
                /> */}
                <Avatar
                  alt="Remy Sharp"
                  variant="square"
                  src={school.logo[0].url}
                  // style={{ width: "80px", height: "80px" }}
                />
              </ListItemAvatar>
              <ListItemText
                // primary={props.school.schoolName}
                primary={school.schoolName}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Private School
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </div>
          <div className="col-xl-3">
            {/* <StarIcon style={{ color: "grey" }} />
            <StarIcon style={{ color: "grey" }} />
            <StarIcon style={{ color: "grey" }} />
            <StarBorderIcon style={{ color: "grey" }} />
            <StarBorderIcon style={{ color: "grey" }} />
            <small>135 likes</small> */}
          </div>
        </div>
        {/* row */}

        {/* <div className="row" style={{ background: "#F8F8F8", height: "71px" }}>
          <div className="col-xl-12 ml-lg-3">
            pien et pellentesque dictum. Aenean eget metus pulvinar, malesuada
            felis eu, egestas enim. Sed egestas ....
          </div>
        </div> */}

        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <small className="ml-lg-3">Topline performance data</small>
              <div className="row">
                <div className="col-xl-4">
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={Waec} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            style={{ fontWeight: "Bolder" }}
                          >
                            {!loading && numWaecScore}
                          </Typography>
                          {" Students with 5 A's and above"} <br />
                          <i style={{ fontSize: "10px" }}>{" WAEC"}</i>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>
                <div className="col-xl-4">
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={neco} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            style={{ fontWeight: "Bolder" }}
                          >
                            {!loading && numJambScore}
                          </Typography>
                          {" Students with 250 and above"} <br />
                          <i style={{ fontSize: "10px" }}>{" JAMB"}</i>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>
                <div className="col-xl-4">
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={lab} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            style={{ fontWeight: "Bolder" }}
                          >
                            {!loading && numNecoScore}
                          </Typography>
                          {" Students with 5 A's and above"} <br />
                          <i style={{ fontSize: "10px" }}>{" NECO"}</i>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>
              </div>
              {/* he */}
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-xl-4">
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={Waec} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            style={{ fontWeight: "Bolder" }}
                          >
                            {!loading && numWaecScore}
                          </Typography>
                          {" Students with 5 A's and above"} <br />
                          <i style={{ fontSize: "10px" }}>{" WAEC"}</i>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>
                <div className="col-xl-4">
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={neco} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            style={{ fontWeight: "Bolder" }}
                          >
                            {!loading && numJambScore}
                          </Typography>
                          {" Students with 250 and above"} <br />
                          {" JAMB"}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>
                <div className="col-xl-4">
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={lab} />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            style={{ fontWeight: "Bolder" }}
                          >
                            {!loading && numNecoScore}
                          </Typography>
                          {" Students with 75% and above"} <br />
                          {" NECO"}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </div>
              </div>
            </div>
          </div>
          <Link
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
            to="#"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </Link>
          <Link
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
            to="#"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </Link>
        </div>

        {/* row */}
      </CardContent>
    </Card>
  );
};
export default LargeCard;
