import React from 'react'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import '../styles/main.css'

import LargeCard from './LargeCard'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
//   root: {
//     minWidth: 275,
//     flexGrow: 1,
//     backgroundColor: "#fff",
//   },
//   bullet: {
//     display: "inline-block",
//     margin: "0 2px",
//     transform: "scale(0.8)",
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
//   large: {
//     width: theme.spacing(9),
//     height: theme.spacing(9),
//   },
// }));

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const ListedSchools = ({ data }) => {
  // const [value, setValue] = React.useState(0);

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div
          className='col-xl-12 pb-xl-5 mb-3'
          style={{ backgroundColor: '#24A768' }}
        >
          <div className='row mx-lg-5'>
            <div className='col-xl-12 mt-lg-4 text-light'>
              <h2>Listed Schools</h2>
              <button
                type='button'
                className='btn bg-custom mb-3'
                data-toggle='modal'
                data-target='#addSchoolModal'
                style={{ marginTop: '33px', float: 'right' }}
              >
                Submit your school
              </button>
              {/* <!-- Modal --> */}
              <div
                className='modal fade'
                id='addSchoolModal'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='addSchoolModalLabel'
                aria-hidden='true'
              >
                <div
                  className='modal-dialog modal-dialog-centered'
                  role='document'
                >
                  <div className='modal-content'>
                    <iframe
                      width='100%'
                      height='660px'
                      title='request form'
                      style={{ border: '0px' }}
                      src='https://bigin.zoho.com/crm/WebFormServeServlet?rid=f6828186cf9c53c3a4aebac12a3c5cc434c460d2dc58b28cd468a353f2098220gidfda9578cca86b5c13716de6430a3038a92cbc5eb10ed77b22d91f6c7ad49baf6'
                    ></iframe>
                  </div>
                </div>
              </div>
              {/* End modal */}
              <small>
                Achieveing schools across Nigeria showcase their students'
                achievements
              </small>{' '}
            </div>
          </div>

          <div className='mx-lg-5'>
            {data.map((school, id) => (
              <Link to={`/school-profile/${school.slug}`} key={school.id}>
                <LargeCard school={school} />
              </Link>
            ))}
          </div>
          <div className='top mb-2'>
            <Button
              style={{ textTransform: 'lowercase' }}
              variant='outlined'
              href='/schools'
            >
              see all schools
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListedSchools
