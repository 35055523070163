import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import "../styles/main.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
}));

const SmallCard = (props) => {
  const classes = useStyles();
  return (
    <Card className="card">
      <CardContent className="text-center justify-content-center">
        <img alt="" src={props.medaltype} className="medal" /> <br />
        <center className="my-3">
          <Avatar
            alt="Remy Sharp"
            src={props.image}
            className={classes.large}
          />
        </center>
        <h6>{props.name}</h6>
        <div className="row detail text-left">
          <div className="col-xl-3 nopadding">School:</div>
          <div
            className="col-xl-9 nopadding text-truncate"
            style={{ color: "#24A768" }}
          >
            {" "}
            {props.school}
          </div>
        </div>
        <div className="row detail text-left">
          <div className="col-xl-3 nopadding">State:</div>
          <div className="col-xl-9 nopadding" style={{ color: "#24A768" }}>
            {" "}
            {props.state} State
          </div>
        </div>
        <div className="row detail text-left">
          <div className="col-xl-3 nopadding">Gender:</div>
          <div className="col-xl-9 nopadding"> {props.gender}</div>
        </div>
        <div className="row detail text-left">
          <div className="col-xl-3 nopadding">Full Score:</div>
          <div className="col-xl-9 nopadding"> {props.score} %</div>
        </div>
      </CardContent>
    </Card>
  );
};
export default SmallCard;
