import React, { useState } from 'react'
import '../styles/main.css'

import { useEffect } from 'react'

const Highlight = ({ student }) => {
  const [scores, setScores] = useState([])
  useEffect(() => {
    let waec = student.exams.filter(
      (exam) => exam.__typename === 'exams_waec_BlockType'
    )[0].grades
    let tempScores = []
    for (var i = 0; i < waec.length; i += 2) {
      tempScores.push([waec[i], waec[i + 1]])
    }
    if (waec.length % 2 === 0) {
      tempScores.push([waec[waec.length - 1]])
    }
    setScores(tempScores)
  }, [student])
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div
          className='col-xl-12'
          style={{ backgroundColor: '#F2F1F1', marginRight: '30px' }}
        >
          {' '}
          <strong className='pt-lg-3' style={{ color: '#000000' }}>
            WASSCE Top Achiever
          </strong>
          <div className='row' style={{ padding: '5px' }}>
            <div className=' col-sm-6 order-1 text-black py-2 d-flex justify-content-center'>
              <div>
                {/* <div
                  style={{
                    zIndex: '1',
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    height: '75%',
                    backgroundColor: '#21316A',
                    opacity: '90%',
                  }}
                >
                  <div className='m-2' id='outer2'>
                    <div
                      id='inner2'
                      className='text-center'
                      style={{ color: '#fff', fontSize: '24px' }}
                    >
                      <p style={{ fontSize: '30px' }}>
                        Launching soon. Stay tuned
                      </p>
                      <div
                        style={{ backgroundColor: '#2C6A8D', width: 'auto' }}
                      >
                        1st January 2021
                      </div>
                      <form className='form-inline mt-lg-5 align-items-center'>
                        <div className='form-group mx-sm-3 mb-2'>
                          <input
                            type='password'
                            className='form-control col-lg-12'
                            id='inputPassword2'
                            placeholder='Email'
                          />
                        </div>
                        <button type='submit' className='btn btn-primary mb-2'>
                          Get in touch
                        </button>
                      </form>
                    </div>
                  </div>
                </div> */}
                <img
                  className='kid'
                  width='467px'
                  height='347px'
                  src={student.profileImage[0].url}
                  alt=''
                  style={{
                    borderRadius: '5px',
                  }}
                />
              </div>
            </div>
            <div
              className=' col-sm-6 order-2 text-black py-2 d-flex justify-content-left'
              style={{ backgroundColor: '#FFF' }}
            >
              <div style={{ width: '100%' }}>
                <h5>
                  {student.firstName} {student.lastName}
                </h5>
                <small>
                  <u>{student.state} State</u>
                </small>{' '}
                <br />
                <small>
                  <u>{student.school[0].schoolName}</u>
                </small>
                <hr className='divider' />
                {scores.map(([col1, col2], index) => (
                  <div className='row' key={index}>
                    {col1 ? (
                      <div className='col'>
                        <div className='input-group mb-2'>
                          <input
                            type='text'
                            className='form-control'
                            id='inlineFormInputGroupUsername2'
                            value={col1.subject}
                            style={{
                              fontSize: '11px',
                              backgroundColor: '#FFF',
                            }}
                            disabled
                          />
                          <div
                            className='input-group-text'
                            style={{
                              backgroundColor: '#24A768',
                              color: '#FFF',
                            }}
                          >
                            {col1.score}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {col2 ? (
                      <div className='col'>
                        <div className='input-group mb-2'>
                          <input
                            type='text'
                            className='form-control'
                            id='inlineFormInputGroupUsername2'
                            value={col2.subject}
                            style={{
                              fontSize: '11px',
                              backgroundColor: '#FFF',
                            }}
                            disabled
                          />
                          <div className='input-group-prepend'>
                            <div
                              className='input-group-text'
                              style={{
                                backgroundColor: '#24A768',
                                color: '#FFF',
                              }}
                            >
                              {col2.score}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-sm-1 order-3"></div> */}
        {/* sidebar was here */}
      </div>
    </div>
  )
}

export default Highlight
