import React, { useState, useEffect } from "react";
// import InputBase from "@material-ui/core/InputBase";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useQuery } from "@apollo/client";
import { SCHOOL_STUDENT_QUERY } from "../queries";
import Loading from "./loading";
import { makeStyles } from "@material-ui/core/styles";
import { fetchScores } from "../utils";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 180,
  },
}));

const proccessData = (data, split = 3) => {
  var res = [];
  for (var i = 0; i < data.length; i += split) {
    res.push(data.slice(i, i + split));
  }
  return res;
};

export const useFetchStudents = (schoolID, limit = null) => {
  const { error, loading, data } = useQuery(SCHOOL_STUDENT_QUERY, {
    variables: { id: schoolID, limit },
  });
  return { error, loading, data };
};

const ListedStudents = ({ id }) => {
  const classes = useStyles();
  const [students, setStudents] = useState([]);

  const [limit, setLimit] = useState(3);
  const { error, loading, data } = useFetchStudents(id, limit);
  useEffect(() => {
    if (data) {
      setStudents(proccessData(data.entries));
    }
  }, [data]);
  if (loading) return <Loading />;
  if (error) {
    console.log(error);
    return <h1>UNKNWON ERROR</h1>;
  }
  console.log(data);
  return (
    <div className="container mt-lg-4 mb-lg-4">
      {students.map((row, index) => (
        <div className="row" key={index}>
          {row.map((student) => {
            const scores = fetchScores(student.exams)
            return (
            <div className="col-sm mb-4" key={student.id}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={student.profileImage[0].url}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h5">
                      {student.firstName + " " + student.lastName}
                    </Typography>
                    {scores}
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          )})}
        </div>
      ))}
      <div className="top">
        <Button
          variant="outlined"
          href="#outlined-buttons"
          onClick={() => setLimit(null)}
        >
          see all
        </Button>
      </div>
    </div>
  );
};
export default ListedStudents;
