import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import Typography from '@material-ui/core/Typography'
import Medal from '../assets/img/medal.png'

// import tab1 from '../assets/img/Rectangle-1.png'
// import tab2 from '../assets/img/Rectangle-2.png'
// import tab3 from '../assets/img/Rectangle.png'

// import Avatar from '@material-ui/core/Avatar'

// import Tabs from '@material-ui/core/Tabs'
// import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'
// import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import '../styles/main.css'
import SmallCard from './SmallCard'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
//   root: {
//     minWidth: 275,
//     flexGrow: 1,
//     backgroundColor: '#fff',
//   },
//   bullet: {
//     display: 'inline-block',
//     margin: '0 2px',
//     transform: 'scale(0.8)',
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
//   large: {
//     width: theme.spacing(9),
//     height: theme.spacing(9),
//   },
// }))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   }
// }

const BestStudent = ({ student }) => {
  // const classes = useStyles()
  // const [value, setValue] = React.useState(0)

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-xl-12 mb-3' style={{ backgroundColor: '#F2F1F1' }}>
          <div className='top text-center'>
            <h2>Best 100 Students In The Country</h2>
            <small>
              This list is compiled based on aggregate scores from WASSCE 2020{' '}
            </small>
          </div>
          <div className='my-xl-5 mx-lg-5'>
            <div className='row my-lg-3'>
              {student.map((stu, index) => (
                <div className='col-sm-4 mb-3' key={index}>
                  {/* Card comes here */}
                  <SmallCard
                    name={stu.firstName + ' ' + stu.lastName}
                    image={stu.profileImage[0].url}
                    medaltype={Medal}
                    score={stu.points}
                    gender={stu.gender}
                    school={stu.school[0]?.schoolName}
                    state={stu.state}
                  />
                </div>
              ))}
            </div>
            <div className='top'>
              <Button
                variant='outlined'
                href='/top-achievers'
                style={{ color: '#24A768' }}
              >
                see all
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BestStudent
