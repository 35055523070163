import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

// import Image from "../assets/img/Rectangle2.png";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";

import CardMedia from "@material-ui/core/CardMedia";

import Typography from "@material-ui/core/Typography";
import { fetchScores } from "../utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  media: {
    height: 180,
  },
}));

const SmallCard2 = ({ name, profileImage, exams }) => {
  const classes = useStyles();
  const [scores, setScores] = React.useState([]);

  React.useEffect( () => {
    exams && setScores(fetchScores(exams));
  }, [exams])

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={profileImage}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h5">
          <div className="row detail text-left">
            <div className="col-xl-2 nopadding">Name:</div>
            <div
              className="col-xl-10 nopadding text-truncate"
              style={{ color: "#24A768" }}
            >
              {" "}
              {name}
            </div>
          </div>
            
          </Typography>

          <Typography gutterBottom variant="h6" component="h5">
          <div className="row detail text-left">
            <div className="col-xl-2 nopadding">School:</div>
            <div
              className="col-xl-10 nopadding text-truncate"
              style={{ color: "#24A768" }}
            >
              {" "}
            </div>
          </div>
          </Typography>
          <Typography gutterBottom variant="h6" component="h5">
          <div className="row detail text-left">
            <div className="col-xl-2 nopadding">State:</div>
            <div
              className="col-xl-10 nopadding text-truncate"
              style={{ color: "#24A768" }}
            >
              {" "}
            </div>
          </div>
          </Typography>

          <Typography gutterBottom variant="h6" component="h5">
          <div className="row detail text-left">
            <div className="col-xl-2 nopadding">Grade:</div>
            <div
              className="col-xl-10 nopadding text-truncate"
              style={{ color: "#24A768" }}
            >
              {" "}
              {scores}
            </div>
          </div>
          </Typography>
          
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
export default SmallCard2;
