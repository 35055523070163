import React from "react";


export const AppDataContext = React.createContext()

export default function AppDataProvider( { children }){
    const [state, setState] = React.useState(10);

    return (
        <AppDataContext.Provider
        value={{
            state,
            setState
        }}
        >
            {children}
        </AppDataContext.Provider>
    )
} 