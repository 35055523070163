import React from 'react'
import Header2 from '../components/Header2'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { SCHOOLDATA_QUERY } from '../queries'
import Loading from '../components/loading'
import { Helmet } from 'react-helmet'

const Title = 'Profile | NEAP'

const SchoolProfile = () => {
  const { schoolSlug } = useParams()
  const { data, loading, error } = useQuery(SCHOOLDATA_QUERY, {
    variables: { slug: schoolSlug },
  })
  if (loading) return <Loading />
  if (error) {
    console.error(error)
    return <h1>AN UNKNOWN ERROR OCCURED!!</h1>
  }
  if (data.entry == null) {
    return <Redirect to='/*' />
  }
  return (
    <div>
      <Helmet>
        <title>{Title}</title>
      </Helmet>
      <Header2 school={data.entry} />
    </div>
  )
}
export default SchoolProfile
