import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import "../styles/main.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SmallCard2 from "../components/SmallCard2";
// import PaginationRounded from "../components/PaginationRounded";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useQuery } from "@apollo/client";
import { STUDENTS_QUERY } from "../queries";
import Loading from "../components/loading";
import { groupBatchData, states } from "../utils";
import { Helmet } from "react-helmet";



const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 180,
  },
}));

const Title = "Top-Achievers | NEAP";

const TopAchievers = () => {
  const classes = useStyles();
  const [state, setState] = React.useState(10);
  const [year, setYear] = React.useState(20);
  const handleChange = (event) => {
    setState(event.target.value);
  };
  const handleChange2 = (event) => {
    setYear(event.target.value);
  };
  const { data, loading } = useQuery(STUDENTS_QUERY, { variables:  { state: state === 10? null : state }});
  const students = data && groupBatchData(data.entries, 3)
  return (
    <div className="container-fluid">
      <Helmet>
        <title>{Title}</title>
      </Helmet>
      {/* <nav className="navbar navbar-expand-md navbar-light">
        <Link className="navbar-brand">
          <img src="../img/logo2.png" height="46" alt="NEAP" />
        </Link>
        <button
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav navbar-center">
            <Link className="nav-item nav-link active">HOME</Link>
            <Link className="nav-item nav-link">STUDENTS</Link>
            <Link className="nav-item nav-link">STATE</Link>
            <Link className="nav-item nav-link" tabindex="-1">
              PROFILES
            </Link>
            <Link className="nav-item nav-link" tabindex="-1">
              ABOUT
            </Link>
            <Link className="nav-item nav-link" tabindex="-1">
              BLOG
            </Link>
          </div>
        </div>
        <div className="navbar-nav ml-auto" style={{ padding: "24px" }}>
          <Link className="nav-item" style={{ color: "#000" }}>
            CONTACT US
          </Link>
        </div>
      </nav> */}
      <Header />
      <div id="banner">
        <div id="inner-banner" className="text-center">
          <strong style={{ fontSize: "48px" }}>2020’s top achievers</strong>
          <p className="text-center">filter by exam and state</p>
        </div>
      </div>
      <div style={{ backgroundColor: "#F8F8F8" }} className="text-center mb-4">
      <FormControl className={classes.margin}>
          <InputLabel id="demo-customized-select-label"></InputLabel>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={year}
            onChange={handleChange2}
            input={<BootstrapInput />}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Exams / Competition</MenuItem>
            <MenuItem value={20}>JAMB</MenuItem>
            <MenuItem value={30}>WACE</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.margin}>
          <InputLabel id="demo-customized-select-label"></InputLabel>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={state}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            <MenuItem value={10}>Nigeria</MenuItem>
                {states.map((state, index) => (
                  <MenuItem value={state} key={index}>{state}</MenuItem>
                ))}
          </Select>
        </FormControl>
        
        <button
          type="button"
          className="btn"
          style={{ marginTop: "33px", marginLeft:"70px", backgroundColor:"#F7700F", color:"#FFFFFF" }}
        >
          Search
        </button>
      </div>

      {/* Cards */}
      <div className="container">
        {loading ? (
          <Loading />
        ) : (
          students.map((row, index) => (
            <div className="row" key={index}>
              {row.map((student) => (
                <div className="col-sm mb-4" key={student.id}>
                  <SmallCard2
                    name={`${student.firstName} ${student.lastName}`}
                    profileImage={student.profileImage[0].url}
                  />
                </div>
              ))}
            </div>
          ))
        )}
        {/* <PaginationRounded /> */}
      </div>
      {/* Footer part */}
      <Footer />
    </div>
  );
};
export default TopAchievers;
