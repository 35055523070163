import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { NavBarQuery } from '../queries'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

// const axios = require("axios");
// const webhookURL =
//   "https://cors-anywhere.herokuapp.com/https://outlook.office.com/webhook/dc4f5d09-70e0-4f16-bf4f-2129a7779e36@629903f5-e9ed-42cf-aa47-f85253599c54/IncomingWebhook/a132e712a8a34c2f95f0275d80d465b8/6f1ddb1b-836b-470b-a790-0bac06d93484";

// if (process.argv.length === 4) {
//   const title = process.argv[2];
//   const message = process.argv[3];
//   console.log("hel", `${title}, ${message}`);
//   postMessageToTeams(title, message);
// } else {
//   console.log("Usage: node post-message-to-teams.js title messageBody");
// }
// async function postMessageToTeams(title, message) {
//   const card = {
//     "@type": "MessageCard",
//     "@context": "http://schema.org/extensions",
//     themeColor: "0072C6", // light blue
//     summary: "Summary description",
//     sections: [
//       {
//         activityTitle: title,
//         text: message,
//       },
//     ],
//   };

//   try {
//     const response = await axios.post(webhookURL, card, {
//       headers: {
//         "content-type": "application/vnd.microsoft.teams.card.o365connector",
//         "content-length": `${card.toString().length}`,
//       },
//     });
//     return `${response.status} - ${response.statusText}`;
//   } catch (err) {
//     return err;
//   }
// }

const Header = () => {
  const { data, error, loading } = useQuery(NavBarQuery)
  if (error || loading) {
    error && console.error(error)
  }
  return (
    <div>
      <div>
        <Link
          className='navbar-brand text-center'
          to='/'
          style={{ textDecoration: 'none', color: '#000000' }}
        >
          <img src='../img/logo.png' height='76' alt='CoolBrand' align='left' />
          <small className='text-logo'>
            <strong style={{ color: '#000000' }}>
              THE NATIONAL EDUCATION AWARDS PROGRAMME
            </strong>{' '}
            <br /> celebrating academic achievement
          </small>
        </Link>

        <div
          className='float-right'
          style={{
            color: '#FFFFFF',
            backgroundColor: '#24A768',
            padding: '6px',
            fontSize: '14px',
          }}
        >
          <MailOutlineIcon style={{ padding: '0px', fontSize: '16px' }} />{' '}
          AWARDS@NEAPNG.ORG
        </div>
      </div>
      <nav className='navbar navbar-expand-md navbar-light'>
        <button
          type='button'
          className='navbar-toggler'
          data-toggle='collapse'
          data-target='#navbarCollapse'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div
          className='collapse navbar-collapse justify-content-end border-gradient border-gradient-purple'
          id='navbarCollapse'
        >
          <div
            className='nav navbar-nav navbar-center'
            style={{
              backgroundColor: '#24A768',
            }}
          >
            {data &&
              data.entry.button.map(
                (button) =>
                  button.__typename === 'button_navbutton_BlockType' &&
                  button.isvisble && (
                    <Link
                      to={button.href}
                      className='nav-item nav-link'
                      key={button.id}
                      style={{
                        color: '#FFFFFF',
                        borderLeft: '2px solid #ffffff',
                      }}
                    >
                      {button.label}
                    </Link>
                  )
              )}
            {/* <Link className="nav-item nav-link">TOP 100</Link>
            <Link className="nav-item nav-link">SCHOOL</Link>
            <Link to="/generic" className="nav-item nav-link" tabIndex="-1">
              PARTNERS
            </Link>
            <Link className="nav-item nav-link" tabIndex="-1">
              ABOUT
            </Link>
            <Link className="nav-item nav-link" tabIndex="-1">
              BLOG
            </Link> */}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
