import React from "react";
const Error404 = () => {
	return (
		<center>
			<h1>Whoops! 404</h1>
			<h6>Page not found</h6>
		</center>
	);
};
export default Error404;
