import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Partners from '../components/Partners'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ARTICLE_QUERY } from '../queries'
import Loading from '../components/loading'

const Article = () => {
  const TITLE = 'Stories | NEAP'
  const { slug } = useParams()
  const { data, error, loading } = useQuery(ARTICLE_QUERY, {
    variables: { slug },
  })

  if (loading) return <Loading />
  if (error) {
    console.error(error)
    return <h1>AN UNKNOWN ERROR OCCURED!!</h1>
  }
  if (data.entry == null) {
    return <Redirect to='/*' />
  }
  return (
    <div style={{ backgroundColor: '#F8F8F8' }}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Header />
      {/* End nav */}

      <div id='banner'>
        <div id='inner-banner' className='text-center'>
          <strong style={{ fontSize: '48px' }}>{data.entry.title}</strong>
        </div>
      </div>

      <div className='container'>
        <p dangerouslySetInnerHTML={{ __html: data.entry.contentField }}></p>
      </div>
      <Partners className='mt-lg-4' />
      <Footer />
    </div>
  )
}

export default Article
