import './App.css'
import HomePage from './pages/Homepage'
import { Route, Switch } from 'react-router-dom'
import Error404 from './components/Error404'
import SchoolProfile from './pages/SchoolProfile'
import TopAchievers from './pages/TopAchievers'
import ExamsAndCompetition from './pages/ExamsAndCompetetion'
// import ContactForm from "./pages/ContactForm";
import Generic from './pages/Generic'
import AppDataProvider from './context/AppDataProvider'
import Article from './pages/Article'

function App() {
  return (
    <AppDataProvider>
      <div>
        <Switch>
          <Route path='/' component={HomePage} exact />
          <Route path='/school-profile/:schoolSlug' component={SchoolProfile} />
          <Route path='/top-achievers' component={TopAchievers} />
          <Route path='/schools' component={ExamsAndCompetition} />
          {/* <Route path="/contact" component={ContactForm} /> */}
          <Route path='/achieving-schools' component={Generic} />
          <Route path='/stories/:slug' component={Article} />
          <Route component={Error404} />
        </Switch>
      </div>
    </AppDataProvider>
  )
}

export default App
