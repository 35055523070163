import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/img/Rectangle 17.png'

const Footer = () => {
  return (
    <div style={{ backgroundColor: '#2D7C56' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-4'>
            <ul className='nav flex-column'>
              <li className='nav-item'>
                <Link className='custom-link nav-link' to='/'>
                  About
                </Link>
              </li>
              <li className='nav-item'>
                {' '}
                <Link className='custom-link nav-link' to='/'>
                  Partnerships
                </Link>
              </li>
              <li className='nav-item'>
                {' '}
                <Link className='custom-link nav-link' to='/'>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-sm-4'>
            {' '}
            <ul className='nav flex-column'>
              <li className='nav-item'>
                <Link to='/top-achievers' className='custom-link nav-link'>
                  Top achievers
                </Link>
              </li>
              <li className='nav-item'>
                {' '}
                <Link to='/schools' className='custom-link nav-link'>
                  Schools
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-sm-4'>
            {' '}
            <ul className='nav flex-column'>
              <li className='nav-item'>
                <Link className='custom-link nav-link' to='/'>
                  twitter
                </Link>
              </li>
              <li className='nav-item'>
                {' '}
                <Link className='custom-link nav-link' to='/'>
                  facebook
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='text-center pb-3' style={{ color: '#fff' }}>
        <p>National Education Awards Programme 2020</p>
        <img src={Logo} alt='logo' />
      </div>
    </div>
  )
}

export default Footer
