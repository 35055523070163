import React from 'react'
// import Kids from "../assets/img/Rectangle-3.png";
// import Image from "../assets/img/Rectangle2.png";
// import Image2 from "../assets/img/Rectangle-5.png";
// import Image3 from "../assets/img/Rectangle-6.png";
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
// import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from '@material-ui/core/InputBase'
// import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
// import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Typography from '@material-ui/core/Typography'
// import {
//   Avatar,
//   Divider,
//   List,
//   ListItem,
//   ListItemAvatar,
//   ListItemText,
// } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ListedStudents from './ListedStudents'
import { useQuery } from '@apollo/client'
import { NavBarQuery } from '../queries'
import Footer from './Footer'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 180,
  },
}))

const Header2 = ({ school }) => {
  const classes = useStyles()
  const [age, setAge] = React.useState(10)
  const handleChange = (event) => {
    setAge(event.target.value)
  }
  const { data, error, loading } = useQuery(NavBarQuery)
  if (error || loading) {
    error && console.error(error)
  }
  return (
    <div className='container-fluid'>
      <div>
        <div>
          <Link
            className='navbar-brand text-center'
            to='/'
            style={{ textDecoration: 'none', color: '#000000' }}
          >
            <img
              src='../img/logo.png'
              height='76'
              alt='CoolBrand'
              align='left'
            />
            <small className='text-logo'>
              <strong style={{ color: '#000000' }}>
                THE NATIONAL EDUCATION AWARDS PROGRAMME
              </strong>{' '}
              <br /> celebrating academic achievement
            </small>
          </Link>

          <div
            className='float-right'
            style={{
              color: '#FFFFFF',
              backgroundColor: '#F7700F',
              padding: '10px',
            }}
          >
            CONTACT US
          </div>
        </div>
        <nav className='navbar navbar-expand-md navbar-light'>
          <button
            type='button'
            className='navbar-toggler'
            data-toggle='collapse'
            data-target='#navbarCollapse'
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          <div
            className='collapse navbar-collapse justify-content-end border-gradient border-gradient-purple'
            id='navbarCollapse'
          >
            <div
              className='nav navbar-nav navbar-center'
              style={{ backgroundColor: '#24A768' }}
            >
              {data &&
                data.entry.button.map(
                  (button) =>
                    button.__typename === 'button_navbutton_BlockType' &&
                    button.isvisble && (
                      <Link
                        to={button.href}
                        className='nav-item nav-link'
                        key={button.id}
                        style={{ color: '#FFFFFF' }}
                      >
                        {button.label}
                      </Link>
                    )
                )}
              {/* <Link className="nav-item nav-link">TOP 100</Link>
            <Link className="nav-item nav-link">SCHOOL</Link>
            <Link to="/generic" className="nav-item nav-link" tabIndex="-1">
              PARTNERS
            </Link>
            <Link className="nav-item nav-link" tabIndex="-1">
              ABOUT
            </Link>
            <Link className="nav-item nav-link" tabIndex="-1">
              BLOG
            </Link> */}
            </div>
          </div>
        </nav>
      </div>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <Link className='custom-breadcrumb'>Home</Link>
          </li>
          <li className='breadcrumb-item'>
            <Link className='custom-breadcrumb'>School Profiles</Link>
          </li>
          <li
            className='breadcrumb-item uppercase custom-breadcrumb active'
            aria-current='page'
          >
            {school.schoolName}
          </li>
        </ol>
      </nav>
      <div className='bg-custom uppercase'>{school.schoolName}</div>
      <div className='container-fluid'>
        <div className='row m-3'>
          <div className='col-md-8 order-1 justify-content-center d-flex'>
            <img
              className='img-fluid'
              width='867px'
              height='347px'
              src={school.images[0].url}
              alt=''
              style={{ borderRadius: '5px' }}
            />
          </div>
          <div className='col-md-4 ml-auto order-2'>
            <h5>Contact Information</h5>
            <strong>Location</strong> <br />
            <div className='mt-lg-2'>
              <small>{school.schoolAddress}</small> <br />
            </div>
            <div className='mt-lg-3'>
              <small>
                Phone : {school.phoneNumber}
                <br /> Email:
                {' ' + school.emailAddress}
              </small>
            </div>
            <Link to={school.schoolWebsite}>
              <Button variant='contained' className='mt-4 bg-custom' fullWidth>
                website
              </Button>
            </Link>
            {/* <div className="bg-light m-2" id="outer">
							<div id="inner">
								<strong>COMING SOON</strong>
							</div>
						</div> */}
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row m-3'>
          <div
            className='col-md-8 restructure4'
            dangerouslySetInnerHTML={{ __html: school.about }}
          />
          <div className='col-md-4 ml-auto restructure'>
            <div
              className='m-2'
              id='outer'
              style={{ backgroundColor: '#E9ECEF' }}
            >
              <div id='inner'>
                <strong>COMING SOON</strong>
              </div>
            </div>
          </div>
        </div>
        <div>
        <strong>
          <h3>Top achievers</h3>
        </strong>
        <div
          style={{ backgroundColor: '#F8F8F8' }}
          className='text-center mb-4'
        >
          <FormControl className={classes.margin}>
            <InputLabel id='demo-customized-select-label'></InputLabel>
            <Select
              labelId='demo-customized-select-label'
              id='demo-customized-select'
              value={age}
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Exam/competition</MenuItem>
              <MenuItem value={20}>Exam/competition</MenuItem>
              <MenuItem value={30}>Exam/competition</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl className={classes.margin}>
            <InputLabel id='demo-customized-select-label'></InputLabel>
            <Select
              labelId='demo-customized-select-label'
              id='demo-customized-select'
              value={year}
              onChange={handleChange2}
              input={<BootstrapInput />}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Year</MenuItem>
              <MenuItem value={20}>2019/2020</MenuItem>
              <MenuItem value={30}>2017/2018</MenuItem>
            </Select>
          </FormControl> */}
          <button
            type='button'
            className='btn bg-custom'
            style={{ marginTop: '33px' }}
          >
            Search
          </button>
        </div>
        <ListedStudents id={Number(school.id)} />
        </div>
        {/* <div>
          <strong>
            <h3>Comments</h3>
          </strong>
          <List className={classes.root}>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <Avatar alt='Lemy Sharp' src='/static/images/avatar/1.jpg' />
              </ListItemAvatar>
              <ListItemText
                primary='Leah Amber'
                secondary={
                  <React.Fragment>
                    {' 3 hrs ago'}
                    <br />
                    <Typography
                      component='span'
                      variant='body2'
                      className={classes.inline}
                      color='textPrimary'
                    >
                      Leah Amber
                    </Typography>
                    {" — I'll be in your neighborhood doing errands this…"}
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant='inset' component='li' />
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <Avatar alt='Dravis Howard' src='/static/images/avatar/2.jpg' />
              </ListItemAvatar>
              <ListItemText
                primary='Daniel Okoro'
                secondary={
                  <React.Fragment>
                    {' 3 hrs ago'}
                    <br />
                    <Typography
                      component='span'
                      variant='body2'
                      className={classes.inline}
                      color='textPrimary'
                    >
                      to Scott, Alex, Jennifer
                    </Typography>
                    {" — Wish I could come, but I'm out of town this…"}
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div> */}
      </div>
      <Footer />
    </div>
  )
}

export default Header2
