import { gql } from "@apollo/client";

export const HOMEPAGE_QUERY = gql`
  query {
    entry(section: "neapHomepage") {
      ... on neapHomepage_neapHomepage_Entry {
        title
        id
        pageDescription
        pageKeywords
        sideBar {
          ... on sideBar_BlockType {
            sidebarTitle
            sidebarImage{
              url(width: 250, height: 250, mode: "stretch")
            }
            sidebarContent
            sidebarLink
          }
        }
        schoolCard {
          ... on school_school_Entry {
            id
            schoolName
            slug
            logo {
              url(width: 50, height: 50)
            }
          }
        }
        highlight: topStudents(limit: 6) {
          id
          ... on students_students_Entry {
            firstName
            lastName
            gender(label: true)
            school {
              ... on school_school_Entry {
                schoolName
                slug
              }
            }
            state(label: true)
            profileImage {
              url
            }
            exams {
              ... on exams_waec_BlockType {
                year
                __typename
                grades {
                  ... on grades_BlockType {
                    score(label: true)
                    subject(label: true)
                  }
                }
              }
              ... on exams_neco_BlockType {
                __typename
                grades {
                  ... on grades_BlockType {
                    score(label: true)
                    subject(label: true)
                  }
                }
              }
              ... on exams_jamb_BlockType {
                __typename
                grades {
                  ... on grades_BlockType {
                    score(label: true)
                    subject(label: true)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const TOP_THREE_STUDENT_QUERY = gql`
  query($state: [QueryArgument]) {
    entry(section: "neapHomepage") {
      ... on neapHomepage_neapHomepage_Entry {
        id
        topStudents( state: $state, limit:3) {
          id
        ... on students_students_Entry {
          firstName
          lastName
          gender(label: true)
          school {
            ... on school_school_Entry {
              schoolName
              slug
            }
          }
          state(label: true)
          profileImage {
            url
          }
          exams {
            ... on exams_waec_BlockType {
              year
              __typename
              grades {
                ... on grades_BlockType {
                  score(label: true)
                  subject(label: true)
                }
              }
            }
            ... on exams_neco_BlockType {
              __typename
              grades {
                ... on grades_BlockType {
                  score(label: true)
                  subject(label: true)
                }
              }
            }
            ... on exams_jamb_BlockType {
              __typename
              grades {
                ... on grades_BlockType {
                  score(label: true)
                  subject(label: true)
                }
              }
            }
          }
        }
      }
      }
    }
  }
`;
export const SCHOOLs_QUERY = gql`
  query($limit: Int, $state: [QueryArgument]) {
    entries(section: "school", limit: $limit, orderBy: "schoolName", state: $state) {
      ... on school_school_Entry {
        id
        schoolName
        slug
        logo {
          url(width: 50, height: 50)
        }
      }
    }
  }
`;
export const SCHOOLDATA_QUERY = gql`
  query($slug: [String]) {
    entry(slug: $slug, section: "school") {
      slug
      id
      ... on school_school_Entry {
        schoolName
        logo {
          url(width: 50, height: 50)
        }
        images {
          url
        }
        about
        emailAddress
        schoolWebsite
        state
        schoolAddress
        phoneNumber
      }
    }
  }
`;
export const SCHOOL_STUDENT_QUERY = gql`
  query($id: Int, $limit: Int) {
    entries(
      section: "students"
      limit: $limit
      relatedTo: [$id]
    ) {
      id
      slug
      ... on students_students_Entry {
        firstName
        lastName
        profileImage {
          url
        }
        school {
          ... on school_school_Entry {
            schoolName
          }
        }

        exams {
          ... on exams_waec_BlockType {
            year
            __typename
            grades {
              ... on grades_BlockType {
                score(label: true)
                subject(label: true)
              }
            }
          }
          ... on exams_neco_BlockType {
            __typename
            grades {
              ... on grades_BlockType {
                score(label: true)
                subject(label: true)
              }
            }
          }
          ... on exams_jamb_BlockType {
            __typename
            grades {
              ... on grades_BlockType {
                score(label: true)
                subject(label: true)
              }
            }
            totalScore
          }
        }
      }
    }
  }
`;

export const STUDENTS_QUERY = gql`
query ($state: [QueryArgument], $limit: Int) {
    entries(
      section: "students"
      state: $state
      limit: $limit
    ) {
      id
      slug
      ... on students_students_Entry {
        firstName
        lastName
        profileImage {
          url
        }
        school {
          ... on school_school_Entry {
            schoolName
          }
        }

        exams {
          ... on exams_waec_BlockType {
            year
            __typename
            grades {
              ... on grades_BlockType {
                score(label: true)
                subject(label: true)
              }
            }
          }
          ... on exams_neco_BlockType {
            __typename
            grades {
              ... on grades_BlockType {
                score(label: true)
                subject(label: true)
              }
            }
          }
          ... on exams_jamb_BlockType {
            __typename
            grades {
              ... on grades_BlockType {
                score(label: true)
                subject(label: true)
              }
            }
            totalScore
          }
        }
      }
    }
  }
`;

export const AchievingScho0olPageQuery = gql`
query {
  entry(section: "neapAchievingSchoolsPage"){
    title
    ... on neapAchievingSchoolsPage_neapAchievingSchoolsPage_Entry{
      contentField
      pageDescription
      pageKeywords
      archievingPageBenefits{
        ... on archievingPageBenefits_BlockType{
          benefitTitle
          benefitDescription
        }
      }
    }
  }
}
`;

export const NavBarQuery = gql`
  query {
    entry(slug: "neap-navbar") {
      ... on navbar_navbar_Entry {
        button {
          ... on button_navbutton_BlockType {
            id
            href
            label
            isvisble
          }
          ... on button_button_BlockType {
            id
            href
            isvisible
            label
          }
        }
      }
    }
  }
`;
export const ARTICLE_QUERY = gql`
query ($slug:[String]){
  entry(slug: $slug, section:"neapGeneric"){
    ... on neapGeneric_neapGeneric_Entry {
      title
      slug
      contentField
      pageDescription
      pageKeywords
    }
  }
}
`