import Typography from "@material-ui/core/Typography";

export const groupBatchData = (data, ncol) => {
  const res = [];
  for (let i = 0; i < data.length; i += ncol) {
    res.push(data.slice(i, i + ncol));
  }
  // let rem = data.length % ncol;
  return res;
};

export const summarizeStudentScore = (exams) => {
  let res = {}
  exams.forEach(exam => {
    const examType = (exam.__typename === "exams_waec_BlockType") ? "waec" : exam.__typename === "exams_neco_BlockType" ? "neco" : null
    if (examType) {
      let scores = {};
      exam.grades.forEach(grade => {
        if(Object(scores).hasOwnProperty(grade.score)){
          scores[grade.score] += 1;
        }
        else{
          scores[grade.score] = 1;
        }
      })
      res[examType] = scores;
    }
  })
  return res;
}


export const fetchScores = (exams) => {
  let res = [];
  const summary = summarizeStudentScore(exams);
  for (let key in summary) {
    if (summary.hasOwnProperty(key)) {
      let parsed = `${key.toUpperCase()} : `;
      for (const score in summary[key]) {
        const count = summary[key][score];
        parsed += `${count}${score} `;
      }
      res.push(
        <Typography variant="body2" color="textSecondary" component="p" key={key}>
          {parsed}
        </Typography>
      );
    }
  }
  return res;
};


export const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];