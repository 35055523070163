import React from 'react'
import { Link } from 'react-router-dom'
import Partner1 from '../assets/img/Rectangle 307.png'
import Partner2 from '../assets/img/Q+.jpg'
import Partner3 from '../assets/img/Rectangle 309.png'
import Partner4 from '../assets/img/Rectangle 310.png'
import Partner5 from '../assets/img/Rectangle 311.png'
import Partner6 from '../assets/img/Rectangle 312.png'

const Partners = () => {
  return (
    <div>
      <div className='text-center mb-lg-4'>
        <h2>Partners</h2>
      </div>
      <div className='container text-center mb-3'>
        <div className='row mb-3'>
          <div className='col-sm-4  mb-3'>
            <Link
              target='_blank'
              onClick={() => {
                window.open('https://education.gov.ng/', '_blank')
              }}
              title='Visit Federal Ministry of Education'
            >
              <div
                className='card text-center partners-card'
                style={{ backgroundColor: '#F9F9F9' }}
              >
                <img
                  src={Partner1}
                  className='card-img-top img-fluid mx-auto'
                  alt='...'
                  style={{ width: '180px', height: '180px', display: 'inline' }}
                />
                <div className='card-body'>
                  <p className='card-text'>
                    <br />
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className='col-sm-4  mb-3'>
            <Link
              target='_blank'
              onClick={() => {
                window.open('/', '_blank')
              }}
              style={{ textDecoration: 'none', color: 'black' }}
              title='Visit the National Awards Program'
            >
              <div
                className='card partners-card'
                style={{ backgroundColor: '#F9F9F9' }}
              >
                <img
                  src={Partner2}
                  className='card-img-top img-fluid mx-auto'
                  alt='...'
                  style={{ width: '180px', height: '180px' }}
                />
                <div className='card-body'>
                  <p className='card-text'>
                    School Management thats fast, secure and reliable
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className='col-sm-4 mb-3'>
            <Link
              target='_blank'
              onClick={() => {
                window.open('https://www.studylab360.com/', '_blank')
              }}
              style={{ textDecoration: 'none', color: 'black' }}
              title='Visit StudyLab360'
            >
              <div
                className='card partners-card'
                style={{ backgroundColor: '#F9F9F9' }}
              >
                <img
                  src={Partner3}
                  className='card-img-top img-fluid mx-auto'
                  alt='...'
                  style={{ width: '180px', height: '180px' }}
                />
                <div className='card-body'>
                  <p className='card-text'>
                    Helping schools improve learning and performance for exams
                    and competitions
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className='row my-lg-3 mb-3'>
          <div className='col-sm-4  mb-3'>
            <Link
              target='_blank'
              onClick={() => {
                window.open('https://comfundme.com/', '_blank')
              }}
              style={{ textDecoration: 'none', color: 'black' }}
              title='Visit Comfundme'
            >
              <div
                className='card text-center partners-card'
                style={{ backgroundColor: '#F9F9F9' }}
              >
                <img
                  src={Partner4}
                  className='card-img-top img-fluid mx-auto'
                  alt='...'
                  style={{ width: '180px', height: '180px', display: 'inline' }}
                />
                <div className='card-body'>
                  <p className='card-text'>
                    Group collections and funding campaigns
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className='col-sm-4 mb-3'>
            <Link
              target='_blank'
              onClick={() => {
                window.open('https://www.veritexapp.com/', '_blank')
              }}
              style={{ textDecoration: 'none', color: 'black' }}
              title='Visit Veritex'
            >
              <div
                className='card partners-card'
                style={{ backgroundColor: '#F9F9F9' }}
              >
                <img
                  src={Partner5}
                  className='card-img-top img-fluid mx-auto'
                  alt='...'
                  style={{ width: '180px', height: '180px' }}
                />
                <div className='card-body'>
                  <p className='card-text'>Business Solutions for SME’s</p>
                </div>
              </div>
            </Link>
          </div>
          <div className='col-sm-4 mb-3'>
            <Link
              target='_blank'
              onClick={() => {
                window.open('https://techvibesltd.com/', '_blank')
              }}
              style={{ textDecoration: 'none', color: 'black' }}
              title='Visit Techvibes'
            >
              <div
                className='card partners-card'
                style={{ backgroundColor: '#F9F9F9' }}
              >
                <img
                  src={Partner6}
                  className='card-img-top img-fluid mx-auto'
                  alt='...'
                  style={{ width: '180px', height: '180px' }}
                />
                <div className='card-body'>
                  <p className='card-text'>
                    IT, Transactions and Services Company
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/* <Button variant='outlined' href='#outlined-buttons'>
          see all
        </Button> */}
      </div>
    </div>
  )
}

export default Partners
