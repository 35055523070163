import React from 'react'
import BestStudent from '../components/BestStudent'
import Header from '../components/Header'
import Highlight from '../components/Highlight'
import ListedSchools from '../components/ListedSchools'
import Topstudent from '../components/TopStudent'
import { useQuery } from '@apollo/client'
import { HOMEPAGE_QUERY } from '../queries'
import Loading from '../components/loading'
import Footer from '../components/Footer'
import Partners from '../components/Partners'
// import { SCHOOLs_QUERY } from '../queries'
// import CookieConsent from "react-cookie-consent";
import $ from 'jquery'
import '../styles/jquery.cookie.js'
import { Helmet } from 'react-helmet'
// import { AppDataContext } from "../context/AppDataProvider";
import Sidebar from '../components/Sidebar'
;<script src='http://code.jquery.com/qunit/qunit-1.14.0.js'></script>
;<script src='http://code.jquery.com/jquery-1.11.1.min.js'></script>

const TITLE = 'Home | NEAP'

const HomePage = () => {
  const { loading, error, data } = useQuery(HOMEPAGE_QUERY)
  if (error || loading) {
    console.error(error)
    return <Loading />
  }

  $(document).ready(function () {
    if ($.cookie('Daniel') == null) {
      $('#myModal').modal('show')
      $('#myModal').modal({
        backdrop: 'static',
        keyboard: false,
      })
      $.cookie('Daniel', '2')
    }
  })

  return (
    <div>
      <Helmet>
        <title>{TITLE}</title>
        <meta name='description' content={data.entry.pageDescription} />
        <meta name='keywords' content={data.entry.pageKeywords} />
      </Helmet>
      <div className='container-fluid'>
        <div
          id='myModal'
          className='modal fade'
          role='dialog'
          data-keyboard='false'
          data-backdrop='static'
          aria-hidden='true'
          data-dismiss='modal'
          data-toggle='modal'
          data-target='.bd-example-modal-lg'
        >
          <div
            className='modal-dialog modal-dialog-centered'
            style={{ width: '450px' }}
          >
            <div className='modal-content'>
              <div class='modal-header'>
                <small class='modal-title'>NEAP</small>
                <button
                  type='button'
                  class='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              {/* <div className='modal-header'>
                <h5 className='modal-title' style={{ color: '#24A768' }}>
                  NEAP
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div> */}
              <div className='modal-body text-center'>
                <iframe
                  className='text-center'
                  frameborder='0'
                  id='iframewin'
                  width='390px'
                  height='305px'
                  title='subscribe'
                  src='https://b8c27620.sibforms.com/serve/MUIEALzc9dvq2QjFPeRyi-aF_iMbtgW5idWheEzi4Ms7_U4lkF5ljzd_pX1bzkUJrEjB8kLNM-mlq2eAfIjN8v2Ya41S0KwCPl7wDxkwx0ObhJDxvl9lov3d-e4q_vY4e-nRShzfMCX9kIHA20xTzmFAVNI3ZlnU_JjOPg1QDcS6277ot-r4Uh4zM6WSrf5vvW4yBT-xWRWDnTrW'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        {/* <CookieConsent
          location="top"
          buttonText="Sure man!!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          enableDeclineButton
        >
          <center>
            This website uses cookies to enhance the user experience.
          </center>
        </CookieConsent> */}
        <Header />
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-xl-9'>
              <Highlight student={data.entry.highlight[0]} />
              <Topstudent />
              <BestStudent student={data.entry.highlight} />
              <ListedSchools data={data.entry.schoolCard} />
              <Partners />
            </div>
            {/* Sidebar contents */}
            <div class='col-xl-3'>
              <Sidebar data={data.entry.sideBar} />
            </div>
            {/* /Sidebar contents */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default HomePage
