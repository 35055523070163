import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Partners from "../components/Partners";
import { useQuery } from "@apollo/client";
import { AchievingScho0olPageQuery } from "../queries";
import Loading from "../components/loading";

const Generic = () => {
  const TITLE = "Achieving-Schools | NEAP";

  const { data, error, loading } = useQuery(AchievingScho0olPageQuery);
  if (error || loading) {
    error && console.error(error);
    return <Loading />;
  }

  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content={data.entry.pageDescription} />
        <meta name="keywords" content={data.entry.pageKeywords} />
      </Helmet>
      <Header />
      {/* End nav */}

      <div style={{ backgroundColor: "#FFF" }}>
        <div className="container" style={{ backgroundColor: "#FFF" }}>
          <div className="row pt-lg-3 pb-lg-5">
            <div className="col-xl-8">
              <div style={{ fontSize: "50px" }}>{data.entry.title}</div>
              <p
                dangerouslySetInnerHTML={{ __html: data.entry.contentField }}
              ></p>
            </div>
            <div className="col-xl-4">
              <iframe
                width="400px"
                height="660px"
                title="Request form"
                style={{ border: "0px" }}
                src="https://bigin.zoho.com/crm/WebFormServeServlet?rid=f6828186cf9c53c3a4aebac12a3c5cc434c460d2dc58b28cd468a353f2098220gidfda9578cca86b5c13716de6430a3038a92cbc5eb10ed77b22d91f6c7ad49baf6"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#FFF" }}>
        <div className="container">
          <div className="mt-lg-3" style={{ fontSize: "38px" }}>
            Benefits of listing your Achieving School
          </div>
          <div className="row pt-lg-3 pb-lg-3">
            {data.entry.archievingPageBenefits.map((benefit) => (
              <div className="col-xl">
                {benefit.benefitTitle} <br />
                <br />{" "}
                <p>
                  {benefit.benefitDescription}
                </p>{" "}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div id="banner">
        <div id="inner-banner" className="text-center">
          <strong style={{ fontSize: "48px" }}>PARTNERS</strong>
        </div>
      </div> */}

      {/* <div className="container">
        <h2>Why do we use it?</h2>
        <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages and web
          page editors now use Lorem Ipsum as their default model text, and a
          search for 'lorem ipsum' will uncover many web sites still in their
          infancy. Various versions have evolved over the years, sometimes by
          accident, sometimes on purpose (injected humour and the like).
        </p>
      </div> */}
      <Partners className="mt-lg-4" />
      <Footer />
    </div>
  );
};

export default Generic;
